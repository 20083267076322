<template>
	<div class="goods-list" >
		<top-search :isList="true" :selectCategory="category_id" :diyJump="true" :searchKeyword="searchKeyword" :preselection="preselection" @changeHotCategory="changeHotCategory" @changeMode="changeMode" @searchKeywordList="searchKeywordList"></top-search>
		<div class="category_lists" ref="scrollBox" @mousemove.stop="handleMouseMove" >
			<div class="item" ref="scrollItem" @click="chooseCategory(item.category_id,index,item.attr_class_id)" :class="category_id == item.category_id ? 'active' : ''" v-for="(item,index) in all_category">
{{item.category_name}}<div :class="item.category_id == category_id ? 'active' : ''" class="line"></div>
			</div>
		</div>
		<div v-loading="category_loading">
			<div class="screen_area" v-if="current_mode == 1 && attr_list.length">
				<div class="screen_item" v-for="(item,index) in attr_list" :key="index" v-if="item.query_type == 1" :class="item.range_field?'area':''">
					<div class="left">{{item.attr_name}}：</div>
					<div class="infos">
						<div class="item" :class="!item.has_select ? 'select':''" @click="chooseAttrData(item)">全部</div>
						<div class="item" v-for="(aItem,aIndex) in item.attr_value_parse" :key="aIndex" @click="chooseAttrData(item,aItem)" :class="aItem.select?'select':''">{{aItem.attr_value_name}}</div>
						<div class="item" @click="chooseDiyScreen(item)" v-if="item.range_field" :class="item.diy_select ? 'select':''">自定义</div>
						<div class="input_area" v-if="item.diy_select">
							<div class="shuru"><input v-model="item['min_'+item.range_field]" /></div>
							<div class="zhi"></div>
							<div class="shuru"><input v-model="item['max_'+item.range_field]" /></div>
							<div class="btn" @click="commitAreaData(item)">确认</div>
						</div>
					</div>
				</div>
				<div class="screen_item select" v-if="other_screen" >
					<div class="left">其它：</div>
					<div class="infos">
						<div class="item" v-if="item.query_type == 2" v-for="(item,index) in attr_list" :key="index">
							<div class="top valuePopupTop" @click.stop="openValues(item)" :class="item.open_status?'active':''">
								{{item.attr_name}}
								<div v-if="item.open_status" class="el-icon-caret-top"></div>
								<div v-else class="el-icon-caret-bottom"></div>
							</div>
							<div class="values valuePopup" v-if="item.open_status">
								<div class="value" @click="chooseAttrData(item)" :class="!item.has_select ? 'select':''">全部</div>
								<div class="value" v-for="(vItem,vIndex) in item.attr_value_parse" :key="vIndex" @click="chooseAttrData(item,vItem)" :class="vItem.select?'select':''">{{vItem.attr_value_name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="screen_list" >
			<div class="screen_item" :class="order=='modify_time'?'active':''" @click="changeScreen('modify_time')">
				最新
				<div class="arrows" :class="order=='modify_time'?sort:''">
					<div class="el-icon-caret-top"></div>
					<div class="el-icon-caret-bottom"></div>
				</div>
			</div>
			<div class="screen_item" :class="order=='price'?'active':''" @click="changeScreen('price')">
				价格
				<div class="arrows" :class="order=='price'?sort:''">
					<div class="el-icon-caret-top"></div>
					<div class="el-icon-caret-bottom"></div>
				</div>
			</div>
			<div class="screen_item" :class="order=='click_num'?'active':''" @click="changeScreen('click_num')">
				人气
				<div class="arrows" :class="order=='click_num'?sort:''">
					<div class="el-icon-caret-top"></div>
					<div class="el-icon-caret-bottom"></div>
				</div>
			</div>
			<div class="screen_item" v-if="current_mode == 1" @click="changeShowStyle()">
				展示
				<img class="icon_img" v-if="show_style == 1" src="@/assets/images/newImg/show1.png" />
				<img class="icon_img" v-if="show_style == 2" src="@/assets/images/newImg/show2.png" />
				<img class="icon_img" v-if="show_style == 3" src="@/assets/images/newImg/show3.png" />
			</div>
		</div>
		<div v-loading="goods_loading">
			<template v-if="current_mode == 1">
				<div class="goods_list one" v-if="show_style == 1">
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<img class="goods_img" :src="item.goods_image ? $img(item.goods_image.split(',')[0],{ size: 'mid' }) : $img(defaultGoodsImage)" alt="" @error="item.goods_image = defaultGoodsImage">
						<div class="goods_info">
							<div class="left">
								<div class="goods_tit">{{item.goods_name}}</div>
								<div class="goods_tag">
									<div class="tag" v-if="item.agent_member_id != 0">官验</div>
									<div class="tag">{{item.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="accredits">
									<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
								</div>
							</div>
							<div class="right">
								<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
								<div class="row first">
									<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
									<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
								</div>
								<div class="row">
									<div class="info">
										<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
										<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
										<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
										<img src="@/assets/images/newImg/bargain.png" @click.stop="evaluateGoods(item,'bargain')" />
										<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
									</div>
									<div class="kefu wx">微信
										<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="goods_list four" v-if="show_style == 2">
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<img class="goods_img" :src="item.goods_image ? $img(item.goods_image.split(',')[0],{ size: 'mid' }) : $img(defaultGoodsImage)" alt="" @error="item.goods_image = defaultGoodsImage">
						<div class="goods_tit">{{item.goods_name}}</div>
						<div class="goods_tags">
							<div class="tag" v-if="item.agent_member_id != 0">官验</div>
							<div class="tag">{{item.category_name}}</div>
							<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
						</div>
						<div class="accredits">
							<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
						</div>
						<div class="bottom">
							<div class="left">
								<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
								<div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div>
								<img src="@/assets/images/newImg/message.png" alt="" @click.stop="evaluateGoods(item,'comment')">
								<div class="iconfont1 icon-gouwuche purchase" :class="item.is_cart?'active':''" @click.stop="purchaseGoods(item)"></div>
							</div>
							<div class="price">￥<div class="number">{{Number(item.price)}}</div></div>
						</div>
					</div>
				</div>
				<div class="goods_list excel" v-if="show_style == 3 && goods_list.length">
					<div class="list_tit">
						<div>分类名称</div>
						<div>商品名称</div>
						<div v-for="(item,index) in attr_list" :key="index" v-if="item.excel_show">{{item.attr_name}}</div>
						<div>发布</div>
						<div>官验</div>
						<div>价格</div>
						<div></div>
					</div>
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<div>{{item.category_name}}</div>
						<div>{{item.goods_name}}</div>
						<div v-for="(aItem,aIndex) in item.excel_show" :key="aIndex">{{aItem.attr_value_name}}</div>
						<div>{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
						<div>{{item.agent_member_id ? '官验':'无'}}</div>
						<div>{{Number(item.price)}}</div>
						<div><img @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" /></div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="goods_list demand">
					<div class="goods_item" v-for="(item,index) in goods_list" :key="index" @click="toGoodsDetail(item.goods_id)">
						<div class="goods_info">
							<div class="left">
								<div class="goods_tit">{{item.goods_name}}</div>
								<div class="goods_tag">
									<div class="tag" v-if="item.agent_member_id != 0">官验</div>
									<div class="tag">{{item.category_name}}</div>
									<div class="tag" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
								</div>
								<div class="accredits">
									<div class="item" v-for="(aItem,aIndex) in item.goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 2">{{aItem.attr_value_name}}</div>
								</div>
							</div>
							<div class="right">
								<div class="price">￥<div class="num">{{Number(item.price)}}</div></div>
								<div class="row first">
									<div class="time">{{$util.timeStampTurnTime(item.create_time).split(' ')[0].replaceAll('-','/')}}</div>
									<div class="kefu" @click.stop="toViewChat(item.member_info.member_id)">客服</div>
								</div>
								<div class="row">
									<div class="info">
										<img class="logo" @click.stop="toMemberInfo(item.member_info.member_id)" v-if="item.member_info" :src="$img(item.member_info.headimg)" @error="item.member_info.headimg = defaultHeadImage" alt="">
										<!-- <div class="iconfont1 icon-xihuan" :class="item.is_collect?'active':''" @click.stop="collectGoods(item)"></div> -->
										<img src="@/assets/images/newImg/message.png" @click.stop="evaluateGoods(item,'comment')" />
										<img src="@/assets/images/newImg/invitation.png" alt="" @click.stop="evaluateGoods(item,'tender')">
										<img class="last" src="@/assets/images/newImg/contact.png" />
									</div>
									<div class="kefu wx">微信
										<img v-if="item.member_info.wechat_personal_qrcode" :src="$img(item.member_info.wechat_personal_qrcode)" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<!-- <div v-if="!goods_list.length" class="empty_text">暂无{{current_mode==1?'资源':'需求'}}</div> -->
		</div>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				layout="prev,pager,next,jumper,total"
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination>
		</div>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
import servicerMessage from "@/components/message/servicerMessage"
import list from "./list";
import topSearch from '@/components/top_search';
export default {
  name: "list",
  components: {
	topSearch,
	servicerMessage
  },
  data: () => {
    return {};
  },
  mixins: [list],
  created() {},
  methods: {}
};
</script>
<style lang="scss">
	.screen_item{
		.choose_type{
			background-color: #F7F8FB;
			border-radius: 5px;
			.el-input__inner{
				border: none;
				outline: none;
				background-color: transparent;
			}
		}
	}
	.pager{
		margin-top: 30px;
		.el-pagination{
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn-prev{
				margin-right: 15px !important;
			}
			.btn-next{
				margin-left: 15px !important;
			}
			.btn-prev,.btn-next{
				min-width: 20px;
				height: 20px;
				margin: 0;
				color: #30373D !important;
				font-weight: bolder !important;
				background-color: transparent;
			}
			.el-pagination__jump{
				margin-left: 20px;
				color: #86909C;
				font-size: 14px;
				line-height: 11px;
				display: flex;
				align-items: center;
				height: auto;
				.el-pagination__editor{
					margin: 0 8px;
					padding: 0;
					height: auto;
					width: auto;
					.el-input__inner{
						height:auto;
						min-width: auto;
						padding: 3px 2px 2px;
						border: none;
						color: #30373D;
						line-height: 14px;
						font-size: 14px;
					}
				}
			}
			.el-pagination__total{
				margin-left: 10px;
				color: #86909C;
				font-size: 14px;
				height: 21px;
				line-height: 21px;
			}
			.el-pager{
				display: flex;
				align-items: center;
				.btn-quicknext,.btn-quickprev{
					height: 21px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: transparent;
				}
				.number{
					padding: 0;
					color: #30373D;
					line-height: 11px;
					height: auto;
					min-width: auto;
					padding: 5px 6px;
					font-size: 14px;
					margin: 0;
					font-weight: 500;
					margin-right: 10px;
					background-color: transparent;
					&:last-child{
						margin-right: 0;
					}
					&:not(.disabled).active{
						color: #30373D;
						font-weight: bolder;
						background-color: #fff;
						border-radius: 1px;
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
.empty_text{
	text-align: center;
}
.goods-list{
	padding-top: 30px;
}
.category_lists{
	width: 100%;
	margin-top: 30px;
	display: flex;
	align-items: center;
	overflow: hidden;
	margin-bottom: 30px;
	.item{
		white-space: nowrap;
		color: #30373D;
		font-size: 19px;
		line-height: 23px;
		margin-right: 58px;
		display: flex;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
		&.active{
			color: #FF3300;
		}
		.line{
			margin-top: 9px;
			width: 34px;
			height: 3px;
			border-radius: 20px;
			background-color: transparent;
			&.active{
				background-color: #FF3300;
			}
		}
	}
}
.screen_area{
	padding: 30px 0 10px 30px;
	border-radius: 5px;
	background-color: #fff;
	.screen_item{
		display: flex;
		align-items: flex-start;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
		&.area{
			margin-top: -5px;
			align-items: baseline;
			.infos{
				.item{
					line-height: 31px;
				}
				.item,
				.input_area{
					margin-bottom: 15px;
				}
			}
		}
		&.select{
			align-items: baseline;
			.infos{
				.item{
					position: relative;
					.top{
						color: #666;
						font-size: 15px;
						line-height: 18px;
						background-color: #F7F8FB;
						padding: 6px 18px;
						border-radius: 5px;
						.el-icon-caret-bottom,
						.el-icon-caret-top{
							margin-left: 2px;
						}
						&.active{
							background-color: #30373D;
							color: #fff;
						}
					}
					.values{
						border-radius: 10px;
						box-shadow: 0px 11px 10px 0px rgba(240,237,246,0.4);
						width: 350px;
						background-color: #fff;
						top: 38px;
						position: absolute;
						padding: 23px 0 0 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-wrap: wrap;
						.value{
							margin-bottom: 20px;
							margin-right: 20px;
							&.select{
								color: #FF3300;
							}
							&:last-child{
								margin-right: auto;
							}
						}
					}
				}
			}
		}
		.left{
			color: #30373D;
			font-size: 15px;
			line-height: 18px;
			min-width: 75px;
			margin-right: 10px;
		}
		.infos{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			flex: 1;
			
			.item{
				color: #30373D;
				font-size: 15px;
				line-height: 18px;
				margin-right: 30px;
				margin-bottom: 20px;
				cursor: pointer;
				&.select{
					color: #FF3300;
				}
			}
			.input_area{
				display: flex;
				margin-bottom: 20px;
				align-items: center;
				.zhi{
					width: 12px;
					height: 1px;
					background-color: #999;
					margin: 0 10px;
				}
				.btn{
					font-size: 13px;
					line-height: 15px;
					color: #fff;
					background-color: #30373D;
					border-radius: 5px;
					margin-left: 20px;
					padding: 8px 27px;
					cursor: pointer;
				}
				.shuru{
					background-color: #F7F8FB;
					padding: 8px 7px;
					color: rgba(48, 55, 61, 0.50);
					font-size: 13px;
					line-height: 15px;
					border-radius: 5px;
					input{
						width: 90px;
						padding: 0;
						outline: none;
						border: none;
						background-color: transparent;
					}
				}
			}
		}
	}
}
.screen_list{
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 30px;
	justify-content: center;
	.screen_item{
		color: rgba(48, 55, 61, 0.60);
		font-size: 15px;
		line-height: 18px;
		padding: 8px 33px;
		background-color: #fff;
		border-radius: 5px;
		margin-right: 41px;
		display: flex;
		align-items: center;
		cursor: pointer;
		&:last-child{
			margin-right: 0;
		}
		.icon{
			color: rgba(48, 55, 61, 0.60);
			margin-left: 8px;
		}
		.arrows{
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-left: 8px;
			div{
				color: rgba(48, 55, 61, 0.60);
				font-size: 12px;
				line-height: 6px;
			}
			&.asc{
				.el-icon-caret-top{
					color: #30373D;
				}
			}
			&.desc{
				.el-icon-caret-bottom{
					color: #30373D;
				}
			}
		}
		.icon_img{
			width: 12px;
			height: 12px;
			display: block;
			margin-left: 8px;
		}
		&.active{
			color: #30373D;
		}
	}
}
.goods_list{
	// margin-top: 40px;
	&.one{
		.goods_item{
			cursor: pointer;
			margin-bottom: 30px;
			display: flex;
			border-radius: 10px;
			background-color: #fff;
			align-items: center;
			.goods_img{
				border-radius: 10px;
				width: 197px;
				height: 197px;
				display: block;
				margin-right: 30px;
			}
			.goods_info{
				flex: 1;
				display: flex;
				height: 197px;
				align-items: center;
				padding: 20px 22px 20px 0;
				box-sizing: border-box;
				.left{
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					flex: 1;
					padding-right: 20px;
					.goods_tit{
						color: #30373D;
						font-size: 19px;
						line-height: 39px;
						font-weight: 600;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						word-break: break-all;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.goods_tag{
						display: flex;
						align-items: center;
						height: 22px;
						overflow: hidden;
						flex-wrap: wrap;
						.tag{
							color: #30373D;
							font-size: 15px;
							line-height: 15px;
							padding: 3px 9px 4px;
							background-color: rgba(48, 55, 61, 0.05);
							border-radius: 3px;
							margin-right: 10px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
					.accredits{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						height: 64px;
						overflow: hidden;
						align-content: flex-start;
						.item{
							color: #30373D;
							font-size: 15px;
							line-height: 20px;
							padding: 0 4px;
							border: 1px solid rgba(48, 55, 61, 0.20);
							border-radius: 3px;
							margin-right: 10px;
							margin-top: 10px;
							box-sizing: border-box;
						}
					}
				}
				.right{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					.price{
						display: flex;
						align-items: baseline;
						color: #FF3300;
						font-size: 13px;
						line-height: 39px;
						.num{
							font-size: 19px;
							font-weight: 600;
						}
					}
					.row{
						display: flex;
						align-items: flex-end;
						&.first{
							margin-bottom: 10px;
							margin-top: 27px;
						}
						.info{
							display: flex;
							align-items: center;
							img{
								width: 18px;
								height: 18px;
								display: block;
								margin-right: 20px;
							}
							.iconfont1{
								font-size: 18px;
								line-height: 1;
								margin-right: 20px;
								color: #999;
								&.active{
									color: #FF3300;
								}
							}
							.logo{
								border-radius: 50%;
								object-fit:cover;
							}
							.last{
								margin-right: 0;
							}
							.purchase{
								margin-right: 0;
								font-size: 15px !important;
								line-height: 15px !important;
							}
						}
						.time{
							color: #999;
							font-size: 13px;
							line-height: 18px;
						}
						.kefu{
							position: relative;
							color: #fff;
							font-size: 13px;
							line-height: 18px;
							padding: 7px 40px;
							border-radius: 60px;
							background-color: #30373D;
							margin-left: 52px;
							&.wx{
								color: #1BCF42;
								background-color: rgba(27, 207, 66, 0.20);
								&:hover{
									img{
										display: block;
									}
								}
							}
							img{
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								display: none;
							}
						}
					}
				}
			}
		}
	}
	&.four{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.goods_item{
			cursor: pointer;
			background-color: #fff;
			border-radius: 10px;
			width: calc( ( 100% - 60px ) / 4 );
			margin-right: 20px;
			box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.55);
			box-sizing: border-box;
			margin-top: 20px;
			border-radius: 10px;
			padding-bottom: 10px;
			&:nth-child(-n+4){
				margin-top: 0;
			}
			&:nth-child(4n){
				margin-right: 0;
			}
			.goods_img{
				width: 100%;
				border-radius: 10px;
				max-height: 285px;
				object-fit: contain;
				margin-bottom: 10px;
			}
			.goods_tit{
				padding: 0 10px;
				color: #30373D;
				font-size: 19px;
				line-height: 39px;
				word-break: break-all;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				margin-bottom: 10px;
			}
			.goods_tags{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				padding: 0 10px;
				flex-wrap: wrap;
				height: 22px;
				overflow: hidden;
				.tag{
					color: #30373D;
					font-size: 15px;
					line-height: 15px;
					padding: 3px 9px 4px;
					background-color: rgba(48, 55, 61, 0.05);
					border-radius: 3px;
					margin-right: 10px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
			.accredits{
				padding: 0 10px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 12px;
				height: 64px;
				overflow: hidden;
				align-content: flex-start;
				.item{
					color: #30373D;
					font-size: 15px;
					line-height: 20px;
					padding: 0 4px;
					border: 1px solid rgba(48, 55, 61, 0.20);
					border-radius: 3px;
					margin-right: 10px;
					margin-bottom: 10px;
				}
			}
			.bottom{
				padding: 0 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.left{
					display: flex;
					align-items: center;
					img{
						width: 18px;
						height: 18px;
						display: block;
						margin-right: 20px;
					}
					.iconfont1{
						font-size: 18px;
						line-height: 1;
						margin-right: 20px;
						color: #999;
						&.active{
							color: #FF3300;
						}
					}
					.logo{
						border-radius: 50%;
						object-fit: cover;
					}
				}
				.price{
					display: flex;
					align-items: baseline;
					color: #FF3300;
					font-size: 13px;
					line-height: 13px;
					.number{
						color: #FF3300;
						font-size: 19px;
						line-height: 20px;
						margin-left: 2px;
					}
				}
			}
		}
	}
	&.excel{
		.list_tit{
			margin-top: 30px;
			background-color: #fff;
			border-radius: 10px;
			padding: 20px;
			display: flex;
			align-items: center;
			div{
				color: #30373D;
				font-size: 15px;
				line-height: 18px;
				font-weight: 600;
				flex: 1;
				// width: 8%;
				// &:nth-child(10){
				// 	width: 9%;
				// }
				&:nth-last-child(3){
					width: 4%;
					flex: none;
				}
				&:nth-child(2){
					width: 13%;
					flex: none;
					padding-right: 10px;
				}
				&:last-child{
					max-width: 18px;
				}
			}
		}
		.goods_item{
			cursor: pointer;
			margin-top: 20px;
			background-color: #fff;
			border-radius: 10px;
			display: flex;
			align-items: center;
			padding: 20px 20px;
			div{
				color: #30373D;
				font-size: 15px;
				line-height: 18px;
				flex: 1;
				// width: 8%;
				// &:nth-child(10){
				// 	width: 9%;
				// }
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				&:nth-last-child(3){
					width: 4%;
					flex: none;
				}
				&:nth-child(2){
					width: 13%;
					flex: none;
					padding-right: 10px;
					word-break: break-all;
				}
				&:last-child{
					max-width: 18px;
					height: 18px;
					img{
						border-radius: 50%;
						width: 100%;
						height: 100%;
						display: block;
						object-fit: cover;
					}
				}
			}
		}
	}
	&.demand{
		.goods_item{
			cursor: pointer;
			margin-bottom: 30px;
			display: flex;
			height: 197px;
			box-sizing: border-box;
			border-radius: 10px;
			background-color: #fff;
			align-items: center;
			padding: 20px 22px;
			box-shadow: 0px 25px 56px 0px rgba(240,237,246,0.55);
			.goods_info{
				flex: 1;
				display: flex;
				align-items: center;
				height: 100%;
				.left{
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					flex: 1;
					padding-right: 20px;
					.goods_tit{
						color: #30373D;
						font-size: 19px;
						line-height: 39px;
						font-weight: 600;
						margin-bottom: 10px;
						word-break: break-all;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.goods_tag{
						display: flex;
						align-items: center;
						height: 22px;
						overflow: hidden;
						flex-wrap: wrap;
						.tag{
							color: #30373D;
							font-size: 15px;
							line-height: 15px;
							padding: 3px 9px 4px;
							background-color: rgba(48, 55, 61, 0.05);
							border-radius: 3px;
							margin-right: 10px;
							&:last-child{
								margin-right: 0;
							}
						}
					}
					.accredits{
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						height: 64px;
						overflow: hidden;
						align-content: flex-start;
						.item{
							color: #30373D;
							font-size: 15px;
							line-height: 20px;
							padding: 0 4px;
							border: 1px solid rgba(48, 55, 61, 0.20);
							border-radius: 3px;
							margin-right: 10px;
							margin-top: 10px;
							box-sizing: border-box;
						}
					}
				}
				.right{
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					.price{
						display: flex;
						align-items: baseline;
						color: #FF3300;
						font-size: 13px;
						line-height: 39px;
						.num{
							font-size: 19px;
							font-weight: 600;
						}
					}
					.row{
						display: flex;
						align-items: flex-end;
						&.first{
							margin-bottom: 10px;
							margin-top: 27px;
						}
						.info{
							display: flex;
							align-items: center;
							.purchase{
								margin-right: 0;
								font-size: 15px !important;
								line-height: 15px !important;
							}
							img{
								width: 18px;
								height: 18px;
								display: block;
								margin-right: 20px;
							}
							.iconfont1{
								font-size: 18px;
								line-height: 1;
								margin-right: 20px;
								color: #999;
								&.active{
									color: #FF3300;
								}
							}
							.logo{
								border-radius: 50%;
								object-fit: cover;
							}
							.last{
								margin-right: 0;
							}
						}
						.time{
							color: #999;
							font-size: 13px;
							line-height: 18px;
						}
						.kefu{
							position: relative;
							color: #fff;
							font-size: 13px;
							line-height: 18px;
							padding: 7px 40px;
							border-radius: 60px;
							background-color: #30373D;
							margin-left: 52px;
							&.wx{
								color: #1BCF42;
								background-color: rgba(27, 207, 66, 0.20);
								&:hover{
									img{
										display: block;
									}
								}
							}
							img{
								position: absolute;
								top: 100%;
								left: 50%;
								transform: translateX(-50%);
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
</style>
