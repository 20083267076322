import { goodsSkuPage } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { relevanceinfo, goodsCategoryInfo } from "@/api/goods/goodscategory"
import { goodsList, categoryList, goodsAttrDetail, deleteCollect, addCollect, deleteCart, addCart  } from "@/api/resource.js"
export default {
    data: () => {
        return {
			preselection:'',
			current_mode:1,
			current_category:0,
			show_style:1,
			currentPage: 1,
			pageSize: 20,
			total:0,
			order:'modify_time',
			sort:'desc',
			goods_loading:true,
			category_loading:true,
			keyword:'',
			searchKeyword:'',
			category_id:'',
			goods_list:[],
			all_category:[],
			attr_list:[],
			other_screen:false,
			toUid:'',
			last_move:'',
        }
    },
    created() {
		this.preselection = this.$route.query.mode==3? 1 : this.$route.query.mode;
		this.current_mode = this.$route.query.mode==3 ? 1 : this.$route.query.mode;
		this.category_id = this.$route.query.category_id || '';
		this.searchKeyword = this.$route.query.keyword || '';
		this.keyword = this.$route.query.keyword || '';
        this.getCategoryList();
		document.addEventListener('click', this.closeAllCategory)
    },
	watch: {
		
	},
	destroyed(){
		document.removeEventListener('click',this.closeAllCategory);
	},
    computed: {
        ...mapGetters(["defaultGoodsImage","defaultHeadImage","token"])
    },
	mounted(){
		if(localStorage.getItem('isKefuList') && this.token){
			this.toUid = 'member_' + localStorage.getItem('isKefuList');
			localStorage.removeItem('isKefuList');
			this.$refs.servicerMessage.show();
		}
	},
    methods: {
		handleMouseMove(e){
			if(this.last_move){
				if(this.last_move==e.clientX) return;
				var scrollLeft = this.$refs.scrollBox.scrollLeft;
				if(this.last_move>e.clientX){
					if(scrollLeft){
						this.$refs.scrollBox.scrollTo(scrollLeft-10,0);
					}
				}else{
					this.$refs.scrollBox.scrollTo(scrollLeft+10,0);
				}
			}
			this.last_move = e.clientX;
		},
		searchKeywordList(e){
			this.keyword = e ;
			this.getGoodsList();
		},
		//点击其余地方关闭筛选数据弹框
		closeAllCategory(e){
			//获取弹窗对象
			var popup = document.getElementsByClassName('valuePopup')[0];
			//判断弹窗对象中是否包含点击对象
			if ( popup && !popup.contains(e.target) ) {
				//如果包含则跳转回之前的页面
				this.attr_list.forEach(item=>{
					item.open_status = false;
				})
			}else{
			}
		},
		changeHotCategory(e){
			this.all_category.forEach((item,index)=>{
				if(item.category_id == e){
					this.chooseCategory(item.category_id,index,item.attr_class_id)
				}
			})
		},
		evaluateGoods(goodsInfo,mode){
			if(this.current_mode==1){
				this.$router.pushToTab('/resource-'+goodsInfo.goods_id+'?status='+mode)
			}else{
				this.$router.pushToTab('/demand-'+goodsInfo.goods_id+'?status='+mode)
			}
		},
		toMemberInfo(id){
			this.$router.push('/personal-'+id);
		},
		toViewChat(id){
			if(!this.token){
				this.$message.warning('请先进行登录');
				localStorage.setItem("isKefuList",id);
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/list?mode="+this.current_mode}});
				},800)
				return;
			}
			this.toUid = 'member_'+id;
			this.$refs.servicerMessage.show();
		},
		toGoodsDetail(goodsId){
			if(this.current_mode==1){
				this.$router.pushToTab('/resource-'+goodsId)
			}else{
				this.$router.pushToTab('/demand-'+goodsId)
			}
		},
		purchaseGoods(info){
			if(!this.token){
				this.$message.error('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/list?mode="+this.current_mode}});
				},800)
				return;
			}
			if(info.is_cart){
				deleteCart({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_cart = 0;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}else{
				addCart({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_cart = 1;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}
		},
		collectGoods(info){
			if(!this.token){
				this.$message.error('请先进行登录');
				setTimeout(()=>{
					this.$router.push({path: '/login', query: {redirect: "/list?mode="+this.current_mode}});
				},800)
				return;
			}
			if(info.is_collect){
				deleteCollect({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_collect = 0;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}else{
				addCollect({
					goods_id:info.goods_id
				})
				.then(res=>{
					if(res.code>=0){
						info.is_collect = 1;
					}else{
						this.$message.error(res.message)
					}
				})
				.catch(err=>{
					this.$message.error(err.message)
				})
			}
		},
		commitAreaData(attrData){
			if(!attrData['min_'+attrData.range_field]){
				this.$message.error('请输入最小'+attrData.range_field_info.name);
				return;
			}
			if(!attrData['max_'+attrData.range_field]){
				this.$message.error('请输入最大'+attrData.range_field_info.name);
				return;
			}
			if(attrData['min_'+attrData.range_field]>attrData['max_'+attrData.range_field]){
				this.$message.error('最大'+attrData.range_field_info.name+'不能小于最小'+attrData.range_field_info.name);
				return;
			}
			this.getGoodsList();
		},
		openValues(attrData){
			this.attr_list.forEach(item=>{
				if(item.query_type==2 && item.attr_id != attrData.attr_id){
					item.open_status = false;
				}
			});
			if(attrData.open_status){
				attrData.open_status = false;
			}else{
				attrData.open_status = true;
			}
		},
		chooseDiyScreen(attrData){
			attrData['min_'+attrData.range_field] = '';
			attrData['max_'+attrData.range_field] = '';
			attrData.diy_select = true;
			attrData.attr_value_parse.forEach(item=>{
				item.select = false;
			})
			attrData.has_select = true;
		},
		chooseAttrData(attrData,aItem=''){
			if(attrData.range_field){
				attrData['min_'+attrData.range_field] = '';
				attrData['max_'+attrData.range_field] = '';
			}
			attrData.diy_select = false;
			if(aItem==''){
				//全部
				attrData.attr_value_parse.forEach(item=>{
					item.select = false;
				})
				attrData.has_select = false;
				this.getGoodsList();
				return;
			}
			if(attrData.attr_type==1){
				//单选
				attrData.attr_value_parse.forEach(item=>{
					item.select = false;
				})
				aItem.select = true;
			}else{
				//多选
				if(aItem.select){
					aItem.select = false;
				}else{
					aItem.select = true;
				}
			}
			attrData.attr_value_parse.forEach((vItem,vIndex)=>{
				if(vItem.select) attrData.has_select = true;
			})
			this.getGoodsList();
		},
		getGoodsAttrDetail(id){
			goodsAttrDetail({
				class_id : id
			})
			.then(res=>{
				if(res.code>=0){
					this.attr_list = this.$util.deepClone(res.data.attribute_list);
					this.attr_list.forEach((item,index)=>{
						this.$set(item,'has_select',false)
						this.$set(item,'diy_select',false)
						if(item.attr_value_format){
							this.$set(item,'attr_value_parse',JSON.parse(item.attr_value_format));
						}
						if(item.query_type==2){
							this.other_screen = true;
							this.$set(item,'open_status',false);
						}
						item.attr_value_parse.forEach(aItem=>{
							this.$set(aItem,'select',false);
						});
						if(item.range_field){
							this.$set(item,'min_'+item.range_field,'');
							this.$set(item,'max_'+item.range_field,'');
						}
					});
					var excel_header = [];
					this.attr_list.forEach((aItem,aIndex)=>{
						if(aItem.excel_show){
							excel_header.push(aItem)
						}
					});
					this.category_loading = false;
					if(this.goods_list.length){
						var ids = [];
						excel_header.forEach(eItem=>{
							ids.push(Number(eItem.attr_id));
						})
						this.goods_list.forEach((gItem,gIndex)=>{
							this.$set(gItem,'excel_show',[]);
							excel_header.forEach((aItem,aIndex)=>{//表头循环
								var all_ids = [];
								gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
									all_ids.push(Number(pItem.attr_id))
								})
								if(gItem.goods_attr_parse){
									gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
										var id =[];
										gItem.excel_show.forEach(eItem=>{
											id.push(Number(eItem.attr_id));
										})
										if(pItem.attr_id == aItem.attr_id){
											if(ids.indexOf(Number(pItem.attr_id))!=-1){
												if(id.indexOf(Number(pItem.attr_id))==-1){
													gItem.excel_show.push(pItem);
												}
											}
										}
									})
									if(all_ids.indexOf(Number(aItem.attr_id))==-1){
										gItem.excel_show.push({attr_value_name:'无'});
									}
								}
							})
						})
					}
				}
			})
		},
		getCategoryList(){
			categoryList({
				goods_type:this.current_mode==1?'resource':'demand'
			})
			.then(res=>{
				if(res.code>=0){
					this.all_category = [{'category_id':'','category_name':'推荐'}]
					this.all_category.push(...this.$util.deepClone(res.data));
					if(!this.category_id){
						this.category_id = this.all_category[0].category_id;
						this.current_category = 0;
						this.getGoodsAttrDetail(this.all_category[0].attr_class_id);
						this.getGoodsList();
					}else{
						this.all_category.forEach((item,index)=>{
							if(item.category_id == this.category_id){
								this.current_category = index;
								this.chooseCategory(item.category_id,index,item.attr_class_id);
							}
						});
					}
				}
			})
		},
		getGoodsList(){
			var data = {
				goods_class:this.current_mode==1?5:6,
				page:this.currentPage,
				page_size:this.pageSize,
				keyword:this.keyword,
				category_id:this.category_id,
				order:this.order,
				sort:this.sort,
				attr:'',
			}
			var attr_data = [];
			this.attr_list.forEach((aItem,aIndex)=>{
				aItem.attr_value_parse.forEach((vItem,vIndex)=>{
					if(vItem.select){
						attr_data.push({'attr_id':aItem.attr_id,'attr_value_id':vItem.attr_value_id})
					}
				})
				if(aItem.range_field && aItem.diy_select){
					//区间
					data['min_'+aItem.range_field] = aItem['min_'+aItem.range_field];
					data['max_'+aItem.range_field] = aItem['max_'+aItem.range_field];
				}
			});
			if(attr_data.length){
				data.attr = JSON.stringify(attr_data);
			}else{
				data.attr = '';
			}
			goodsList(data)
			.then(res=>{
				if(res.code>=0){
					this.total = res.data.count;
					this.goods_list = this.$util.deepClone(res.data.list);
					this.goods_list.forEach(item=>{
						if(item.goods_attr_format){
							this.$set(item,'goods_attr_parse',JSON.parse(item.goods_attr_format))
						}
					})
					if(this.attr_list.length){
						var excel_header = [];
						this.attr_list.forEach((aItem,aIndex)=>{
							if(aItem.excel_show){
								excel_header.push(aItem)
							}
						});
						var ids = [];
						excel_header.forEach(eItem=>{
							ids.push(Number(eItem.attr_id));
						})
						this.goods_list.forEach((gItem,gIndex)=>{
							this.$set(gItem,'excel_show',[]);
							excel_header.forEach((aItem,aIndex)=>{//表头循环
								var all_ids = [];
								gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
									all_ids.push(Number(pItem.attr_id))
								})
								if(gItem.goods_attr_parse){
									gItem.goods_attr_parse.forEach((pItem,pIndex)=>{
										var id =[];
										gItem.excel_show.forEach(eItem=>{
											id.push(Number(eItem.attr_id));
										})
										if(pItem.attr_id == aItem.attr_id){
											if(ids.indexOf(Number(pItem.attr_id))!=-1){
												if(id.indexOf(Number(pItem.attr_id))==-1){
													gItem.excel_show.push(pItem);
												}
											}
										}
									})
									if(all_ids.indexOf(Number(aItem.attr_id))==-1){
										gItem.excel_show.push({attr_value_name:'无'});
									}
								}
								
							})
						})
					}
					this.goods_loading = false;
				}
			})
		},
		changeScreen(order){
			if(this.order == order){
				this.sort = this.sort=='desc'?'asc':'desc';
			}else{
				this.order = order;
				this.sort = 'desc';
			}
			this.refresh();
		},
		refresh(){
			this.goods_loading = true;
			this.getGoodsList();
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		changeShowStyle(){
			if(this.category_id){
				if(this.show_style == 3){
					this.show_style = 1;
				}else{
					this.show_style ++ ;
				}
			}else{
				if(this.show_style == 2){
					this.show_style = 1;
				}else{
					this.show_style ++ ;
				}
			}
		},
		chooseCategory(category_id,index,class_id){
			if(!category_id){
				this.show_style = 1;
			}
			this.goods_loading = true;
			this.category_loading = true;
			this.current_category = index;
			this.category_id = category_id;
			this.attr_list = [];
			this.other_screen = false;
			this.getGoodsAttrDetail(class_id);
			this.getGoodsList();
			 /**
				* 1)先让选中的元素滚到可视区域的最左边 scrollLeft
				* 2)接着向右移动容器一半的距离 containWidth / 2
				* 3)最后向左移动item一半的距离 offsetWidth / 2
			*/
			// let lastSpot = this.$refs.scrollBox.scrollLeft;
			// const nextSpace = 50; //每次移动距离
			// let scrollItemTimer = setInterval(() => {
			// 	this.$nextTick(() => {
			// 		let offsetWidth = this.$refs.scrollItem[this.current_category].offsetWidth; //item
			// 		let scrollLeft = this.$refs.scrollItem[this.current_category].offsetLeft; //选中的元素滚到可视区域的最左边
			// 		const containWidth = this.$refs.scrollBox.offsetWidth; //容器的宽度
			// 		if(this.$refs.scrollItem[0].offsetLeft == 0){
			// 			var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2; //最终要停留的点
			// 		}else{
			// 			var resultSpot = scrollLeft + offsetWidth / 2 - containWidth / 2 - this.$refs.scrollItem[0].offsetLeft; //最终要停留的点
			// 		}
			// 		if (Math.abs(lastSpot - resultSpot) < nextSpace) {
			// 			this.getGoodsList();
			// 			clearInterval(scrollItemTimer);
			// 		}
			// 		if (resultSpot >= lastSpot) {
			// 			lastSpot = lastSpot + nextSpace;
			// 		} else {
			// 			lastSpot = lastSpot - nextSpace;
			// 		}
			// 		this.$refs.scrollBox.scrollTo(lastSpot, 0);
			// 	});
			// }, 15);
		},
		changeMode(mode){
			this.current_mode = mode;
			this.goods_loading = true;
			this.category_id = '';
			this.show_style = 1;
			this.current_category = 0;
			this.getCategoryList();
			// this.getGoodsList();
		},
	},
}
